import headerList from "@/components/common/header-list.vue";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import { _ } from "vue-underscore";

export default {
  data: () => ({
    roleList: [],
    searchText: "",
    showLoader: false,
    selectedFile: [],
    selected: "",
    selectedId: 0,
    selectedRows: [],
    sortColumn: "name",
    sortDirection: "asc",
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    recordTakeOptions: [25, 50, 100, 200],
  }),
  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },
  computed: {
    selectAllList() {
      // this.selectedRows = this.roleList
      if (this.roleList.length > 0) {
        return (
          _.where(this.roleList, { isSelected: true }).length ===
          this.roleList.length
        );
      }
      return false;
    },
    hasRoleShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "List" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "List" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "List" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasRoleAddPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Store" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Store" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Store" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasRoleDeletePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Delete" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Delete" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Delete" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasRoleUpdatePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Update" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Update" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Update" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasRoleCopyPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Role Copy" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Role Copy" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Role Copy" })
                    .length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
  },
  methods: {
    applySort(columnName) {
      if (this.sortColumn === columnName) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      this.getAll();
    },
    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        this.roleList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      this.modal7;
      _vm.axios
        .post("/roles-deletes", { ids: ids })
        .then(function () {
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {
          _vm.getAll();
        });
    },
    // getNextPage(page) {
    //   this.$refs.paginate.selected = page;
    //   this.paginate.current_page = page;
    //   this.getAll();
    // },
    search() {
      this.getAll();
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    checkUncheckedList(event) {
      this.roleList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },
    copyRole(roles, event) {
      let _vm = this;
      let _msg = "Are you sure you want to Duplicate this Role ?";

      this.$dialog
        .confirm(_msg)
        .then(function () {
          _vm.axios
            .post("/roles-copy/id?id=" + roles.id)
            .then(function (response) {
              _vm.getAll();
              _vm.selectedId = 0;
            });
        })
        .catch(function () {
          _vm.getAll();
        });
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      this.axios
        .get("/roles" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          let data = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          for (let index = 0; index < data.length; index++) {
            data[index]["isSelected"] = false;
          }
          _vm.roleList = data;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function (error) {});
    },
    handleCheck(e) {
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          this.selectedRows = this.roleList.map((item) => "checkbox" + item.id);
          this.roleList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          this.selectedRows = [];
          this.roleList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          this.selectedRows.push(e.target.id);
        } else {
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getAll();
  },
};
